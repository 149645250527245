import { Link } from "@reach/router"
import React from "react"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { isLoggedIn, getUser } from "../services/auth"

/// <reference types= "../utils/im">
import livejacket from "../images/icons8-lifebuoy-96.png"
import book from "../images/icons8-physics-book-96.png"
import onelineSupport from "../images/icons8-online-support-96.png"
import calendar from "../images/icons8-calendar-plus-96.png"
import { navigate } from "gatsby"

export const MemberFrontPage = () => (
  <React.Fragment>
    <h1>Hello {isLoggedIn() ? getUser().fullName : " "}!</h1>
    <Container>
      <Row>
        <Col md="4">
          <Card className="member-activities">
            <Card.Header>
              <img src={book}></img>
              <Card.Title>Book</Card.Title>
            </Card.Header>
            <Card.Body>
              <p>access the "node-opcua" by example book online.</p>
              <p>always up to date with the most recent version</p>
              <p>download examples</p>
              <p>provide feedback</p>
            </Card.Body>
            <Card.Footer>
              <Button
                style={{ width: "100%" }}
                onClick={() =>
                  navigate("/book/01-introduction/s01-introduction")
                }
              >
                Read the book
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col md="4">
          <Card className="member-activities">
            <Card.Header>
              <img src={livejacket}></img>
              <Card.Title>Assistance</Card.Title>
            </Card.Header>
            <Card.Body>
              <p>open a support ticket</p>
              <p>get a dedicated response within 1 business day by email</p>
              <p>you're entitled to two tickets per calendar month</p>
            </Card.Body>
            <Card.Footer>
              <Button
                style={{ width: "100%" }}
                onClick={() => navigate("/support/form")}
              >
                Send us a question
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col md="4">
          <Card className="member-activities">
            <Card.Header>
              <img src={onelineSupport}></img>
              {/* <img src={calendar}></img> */}
              <Card.Title>Book a Support Call</Card.Title>
            </Card.Header>
            <Card.Body>
              <p>request a 1 hour support session by video conferencing</p>
              <p>get a slot within 2 business day</p>
              <p>you're entitled to one free request per quarter</p>
              <p></p>
            </Card.Body>
            <Card.Footer>
              <Button
                style={{ width: "100%" }}
                onClick={() => navigate("/support/form")}
              >
                Book a Meeting with us
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col md="4" className="member-activities">
          <Card className="member-activities">
            <Card.Header>
              <Card.Title>Experimental Zone</Card.Title>
            </Card.Header>
            <Card.Body>
              <p>comming soon ... </p>
              <p></p>
            </Card.Body>
            <Card.Footer>
              <Button
                style={{ width: "100%" }}
                // onClick={() => navigate("/support/form")}
              >
                Coming soon
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
    You are logged in, so check your <Link to="/app/profile">profile</Link>
  </React.Fragment>
)
