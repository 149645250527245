import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Products } from "./Products"
import { Image } from "./Image"
import { Link } from "gatsby"

export const PublicPage = () => {
  return (
    <React.Fragment>
      <Image style={{ maxWidth: "400px" }} />
      <p>
        This portal is made available to node-opcua support subscription
        holders. If you have a support agreement, please use the provided login
        information to submit your ticket. A response will follow within the
        time frame specified in your Service Level Agreement.
      </p>
      <Products />
      <Row>
        <Col>
          <p>
            You should hold a subscription and{" "}
            <Link to="/app/login">log in</Link> to see restricted content
          </p>
          <p>
            To request a support account or for assistance logging in, send a
            mail to{" "}
            <a href="mailto:contact@sterfive.com">contact@sterfive.com</a>
          </p>
        </Col>
      </Row>
    </React.Fragment>
  )
}
