import React, { useEffect } from "react"
// import Image from "../components/Image"
// import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"

import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
import { isLoggedIn } from "../services/auth"
import { Col, Container, Row } from "react-bootstrap"
import { installMemberfulHook } from "../services/memberful"
import { PublicPage } from "../components/PublicPage"
import { MemberFrontPage } from "../components/MemberFrontPage"

deckDeckGoHighlightElement()

const IndexPage = () => {
  useEffect(() => {
    installMemberfulHook()
  }, [])
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  /*  const h = Helmet(
    <>
      <meta charSet="utf-8" />
      <title>{data.site.siteMetadata?.title || `Title`}</title>
    </>
  )
*/
  return (
    <Layout>
      <SEO title="NodeOPCUA support by Sterfive" />
      <Container>
        <Row>
          <Col>{isLoggedIn() ? <MemberFrontPage /> : <PublicPage />}</Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
